import { reducer as formReducer } from "redux-form";
import auth from "./auth";
import layout from "./layout";
import dsim from "./dsim";
import ueinfo from "./ueinfo";
import gateway from "./gateway";
import cell from "./cell";
import order from "./order";
import phone from "./phone";
import camera from "./camera";
import plan from "./plan";
import payout from "./payout";
import wallet from "./wallet";
import operator from "./operator";
import subscriber from "./subscriber";
import invoice from "./invoice";
import block from "./block";
import user from "./user";

const reducers = {
  auth,
  layout,
  dsim,
  ueinfo,
  gateway,
  cell,
  order,
  phone,
  camera,
  plan,
  payout,
  wallet,
  invoice,
  block,
  operator,
  subscriber,
  user,
  form: formReducer,
};

export default reducers;
