import React from "react";

const PricingTable = (props) => {
  const onClickPlan = (method) => {
    if (props.isPending) {
      alert("Can not change subscription type while pending.");
      return;
    }
    props.onSetPlan(method);
  };

  return (
    <div>
      <section className="py-12">
        <div className="max-w-7xl mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {/* BASIC */}
            <div className="bg-white [box-shadow:0_0_20px_rgba(0,0,0,0.08)] rounded-[5px] relative overflow-hidden">
              <div className="h-[60px] bg-[#5846F9] text-white text-[20px] font-normal flex items-center justify-center">
                Basic
              </div>
              <p className="text-[#c5c2c2] text-sm mt-4 ml-4">
                <span
                  className="
                  inline-block 
                  relative 
                  pt-[25px] pl-[15px] pb-[20px] pr-0 
                  text-[30px] 
                  font-semibold 
                  text-[#5846F9] 
                  before:content-['$'] 
                  before:text-[16px] 
                  before:font-semibold 
                  before:absolute 
                  before:top-[25px] 
                  before:left-0
                "
                >
                  99
                </span>{" "}
                / month / small
              </p>
              <ul className="pt-0 pb-[10px] px-4 mt-2">
                <li className="pb-4 leading-[18px] list-none font-medium">
                  Unlimited Seats
                </li>
                <li className="pb-4 leading-[18px] list-none font-medium">
                  24/7 Support
                </li>
                <li className="pb-4 leading-[18px] list-none font-medium">
                  99.999% Uptime SLA
                </li>
                <li className="pb-4 leading-[18px] list-none font-medium">
                  Dedicated Technicians
                </li>
                <li className="pb-4 leading-[18px] list-none font-medium">
                  Free dSIMs & Data Plans
                </li>
                <li className="pb-4 leading-[18px] list-none font-medium">
                  Indoor 5G Coverage
                </li>
                <li className="pb-4 leading-[18px] list-none font-medium">
                  SIM Swap Protection
                </li>
                <li className="pb-4 leading-[18px] list-none font-medium text-black line-through opacity-30">
                  5G AI Video Analytics
                </li>
                <li className="pb-4 leading-[18px] list-none font-medium text-black line-through opacity-30">
                  5G Neutral Hosting
                </li>
                <li className="pb-4 leading-[18px] list-none font-medium text-black line-through opacity-30">
                  Peer Node Validation
                </li>
              </ul>
              <button
                onClick={() => onClickPlan("Basic")}
                className={`
                  text-[11px]
                  font-semibold
                  rounded-[5px]
                  w-[150px]
                  border-none
                  text-white
                  mb-5
                  h-[40px]
                  leading-[40px]
                  px-5
                  hover:opacity-50
                  mx-auto
                  block
                  cursor-pointer
                  ${props.plan === "Basic" ? "bg-[#10be3c]" : "bg-[#603FF1]"}
                `}
              >
                {props.plan === "Basic" ? "Subscribed" : "Get Started"}
              </button>
            </div>

            {/* STANDARD */}
            <div className="bg-white [box-shadow:0_0_20px_rgba(0,0,0,0.08)] rounded-[5px] relative overflow-hidden">
              <div className="h-[60px] bg-[#5846F9] text-white text-[20px] font-normal flex items-center justify-center">
                Standard
              </div>
              <p className="text-[#c5c2c2] text-sm mt-4 ml-4">
                <span
                  className="
                  inline-block 
                  relative 
                  pt-[25px] pl-[15px] pb-[20px] pr-0 
                  text-[30px] 
                  font-semibold 
                  text-[#5846F9] 
                  before:content-['$'] 
                  before:text-[16px] 
                  before:font-semibold 
                  before:absolute 
                  before:top-[25px] 
                  before:left-0
                "
                >
                  199
                </span>{" "}
                / month / small
              </p>
              <ul className="pt-0 pb-[10px] px-4 mt-2">
                <li className="pb-4 leading-[18px] list-none font-medium">
                  Unlimited Seats
                </li>
                <li className="pb-4 leading-[18px] list-none font-medium">
                  24/7 Support
                </li>
                <li className="pb-4 leading-[18px] list-none font-medium">
                  99.999% Uptime SLA
                </li>
                <li className="pb-4 leading-[18px] list-none font-medium">
                  Dedicated Technicians
                </li>
                <li className="pb-4 leading-[18px] list-none font-medium">
                  Free dSIMs & Data Plans
                </li>
                <li className="pb-4 leading-[18px] list-none font-medium">
                  Indoor 5G Coverage
                </li>
                <li className="pb-4 leading-[18px] list-none font-medium">
                  SIM Swap Protection
                </li>
                <li className="pb-4 leading-[18px] list-none font-medium">
                  5G AI Video Analytics
                </li>
                <li className="pb-4 leading-[18px] list-none font-medium text-black line-through opacity-30">
                  5G Neutral Hosting
                </li>
                <li className="pb-4 leading-[18px] list-none font-medium text-black line-through opacity-30">
                  Peer Node Validation
                </li>
              </ul>
              <button
                onClick={() => onClickPlan("Standard")}
                className={`
                  text-[11px]
                  font-semibold
                  rounded-[5px]
                  w-[150px]
                  border-none
                  text-white
                  mb-5
                  h-[40px]
                  leading-[40px]
                  px-5
                  hover:opacity-50
                  mx-auto
                  block
                  cursor-pointer
                  ${props.plan === "Standard" ? "bg-[#10be3c]" : "bg-[#603FF1]"}
                `}
              >
                {props.plan === "Standard" ? "Subscribed" : "Get Started"}
              </button>
            </div>

            {/* ADVANCED */}
            <div className="bg-white [box-shadow:0_0_20px_rgba(0,0,0,0.08)] rounded-[5px] relative overflow-hidden">
              <div className="h-[60px] bg-[#5846F9] text-white text-[20px] font-normal flex items-center justify-center">
                Advanced
              </div>
              <p className="text-[#c5c2c2] text-sm mt-4 ml-4">
                <span
                  className="
                  inline-block 
                  relative 
                  pt-[25px] pl-[15px] pb-[20px] pr-0 
                  text-[30px] 
                  font-semibold 
                  text-[#5846F9] 
                  before:content-['$'] 
                  before:text-[16px] 
                  before:font-semibold 
                  before:absolute 
                  before:top-[25px] 
                  before:left-0
                "
                >
                  399
                </span>{" "}
                / month / small
              </p>
              <ul className="pt-0 pb-[10px] px-4 mt-2">
                <li className="pb-4 leading-[18px] list-none font-medium">
                  Unlimited Seats
                </li>
                <li className="pb-4 leading-[18px] list-none font-medium">
                  24/7 Support
                </li>
                <li className="pb-4 leading-[18px] list-none font-medium">
                  99.999% Uptime SLA
                </li>
                <li className="pb-4 leading-[18px] list-none font-medium">
                  Dedicated Technicians
                </li>
                <li className="pb-4 leading-[18px] list-none font-medium">
                  Free dSIMs & Data Plans
                </li>
                <li className="pb-4 leading-[18px] list-none font-medium">
                  Indoor 5G Coverage
                </li>
                <li className="pb-4 leading-[18px] list-none font-medium">
                  SIM Swap Protection
                </li>
                <li className="pb-4 leading-[18px] list-none font-medium">
                  5G AI Video Analytics
                </li>
                <li className="pb-4 leading-[18px] list-none font-medium">
                  5G Neutral Hosting
                </li>
                <li className="pb-4 leading-[18px] list-none font-medium">
                  Peer Node Validation
                </li>
              </ul>
              <button
                onClick={() => onClickPlan("Advanced")}
                className={`
                  text-[11px]
                  font-semibold
                  rounded-[5px]
                  w-[150px]
                  border-none
                  text-white
                  mb-5
                  h-[40px]
                  leading-[40px]
                  px-5
                  hover:opacity-50
                  mx-auto
                  block
                  
                  cursor-pointer
                  ${props.plan === "Advanced" ? "bg-[#10be3c]" : "bg-[#603FF1]"}
                `}
              >
                {props.plan === "Advanced" ? "Subscribed" : "Get Started"}
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PricingTable;
