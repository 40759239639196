import React, { useState } from "react";
import { MenuIcon } from "lucide-react";
import { useAppKit, useAppKitAccount } from "@reown/appkit/react";
import { connect } from "react-redux";

import { toggleMobileNavVisibility } from "../../redux/reducers/layout";

import LocalStorageHelper from "../../util/LocalStorageHelper";
import AuthHelper from "../../util/AuthHelper";
import NavbarDropDownMenu from "./DropDownMenu";

export const Navbar = (props) => {
  const { open, close } = useAppKit();
  const { isConnected, address } = useAppKitAccount();

  async function logout() {
    await AuthHelper.logout();
  }

  const user = LocalStorageHelper.getUserInfo();

  return (
    <nav className="w-[95%] m-auto">
      <section className="flex justify-between items-center p-4 w-full">
        <div className="flex items-center gap-2 text-black">
          <i
            onClick={() => props.setSidebarOpened(!props.sidebarOpened)}
            className={`brand-menu-left ${
              props.sidebarOpened
                ? "fas fa-chevron-left"
                : "fas fa-chevron-right"
            }`}
          />
        </div>

        <div className="relative">
          <NavbarDropDownMenu user={user} />
        </div>
      </section>
    </nav>
  );
};

const mapDispatchToProp = (dispatch) => ({
  toggleMobileNavVisibility: () => dispatch(toggleMobileNavVisibility()),
});

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, mapDispatchToProp)(Navbar);
