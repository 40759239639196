import React from "react";
export const Button = ({ variant, onClick, children }) => {
  return (
    <button
      className="bg-[#7b6df9] text-white px-4 py-2 rounded-md hover:bg-[#6659d2]"
      onClick={onClick}
    >
      {children}
    </button>
  );
};
