import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Table } from "react-bootstrap";
import UserModal from "./components/UserModal";
import ApiHelper from "../../util/ApiHelper";
import operator from "../../redux/reducers/operator";
import Http from "../../util/Http";
import Skeleton from "../../components/ui/Skeleton";
import { toast } from "react-toastify";
export const UserOverview = () => {
  const location = useLocation();

  const [users, setUsers] = useState([]);
  const currentUser = useSelector((state) => state.auth.user);
  const operators = useSelector((state) => state.operator.operators);
  const [loading, setLoading] = useState(false);
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [userModalData, setUserModalData] = useState(null);
  const [operatorId, setOperatorId] = useState(null);
  const [businessName, setBusinessName] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const operator = await ApiHelper.fetchOperatorById(
          currentUser.operatorId
        );
        let response = await ApiHelper.fetchUsersByOperatorId(
          currentUser.operatorId
        );
        setUsers(response.data);
        setOperatorId(currentUser.operatorId);
        setBusinessName(operator.businessName);
      } catch (error) {
        console.log("ssß", error);
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [location.pathname, currentUser.operatorId]);

  const openAddUser = () => {
    setUserModalOpen(true);
    setUserModalData(null);
  };

  const openEditUser = async (userId) => {
    try {
      const user = await ApiHelper.fetchUserById(operatorId, userId);
      setUserModalOpen(true);
      setUserModalData(user);
    } catch (error) {
      console.error("Error fetching user:", error);
    }
  };

  const addUser = async (userData) => {
    setUserModalOpen(false);

    try {
      const success = await ApiHelper.createUser(operatorId, userData);
      if (!success) {
        toast.error("Error creating new user");
      } else {
        await ApiHelper.fetchUsers(operatorId);
      }
    } catch (error) {
      console.error("Error adding user:", error);
      toast.error("Error creating new user");
    }
  };

  const updateUser = async (userData) => {
    setUserModalOpen(false);

    try {
      const result = await ApiHelper.updateUser(
        operatorId,
        userData.userId,
        userData
      );

      if (!result) {
        toast.error(`Error updating user: ${userData.userId}`);
      } else {
        await ApiHelper.fetchUsers(operatorId);
      }
    } catch (error) {
      console.error("Error updating user:", error);
      toast.error(`Error updating user: ${userData.userId}`);
    }
  };

  const deleteUser = async (user) => {
    if (!window.confirm(`Are you sure you want to delete user ${user.email}?`))
      return;

    try {
      const result = await ApiHelper.deleteUser(operatorId, user.id);
      await ApiHelper.fetchUsers(operatorId);

      if (!result) {
        toast.error(`Error deleting user: ${user.id}`);
      }
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error(`Error deleting user: ${user.id}`);
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="header overview__header">
              <h4>Users ({businessName})</h4>
              <Button
                variant="primary"
                className="overview__button"
                onClick={openAddUser}
              >
                New User
              </Button>
            </div>
            <div className="content overview__content">
              {loading ? (
                <Skeleton />
              ) : (
                <Table
                  className="overview__table"
                  striped
                  bordered
                  hover
                  responsive
                >
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Role</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  {users?.length > 0 ? (
                    <tbody>
                      {users?.map((user) => (
                        <tr key={user.id}>
                          <td>
                            {user.firstName} {user.lastName}
                          </td>
                          <td>{user.email}</td>
                          <td>{user.role}</td>
                          <td>{user.status}</td>
                          <td
                            style={{
                              textAlign: "center",
                              justifyContent: "center",
                              display: "flex",
                              border: "none",
                            }}
                          >
                            <Button
                              variant="danger"
                              onClick={() => deleteUser(user)}
                            >
                              Delete
                            </Button>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Button
                              variant="info"
                              onClick={() => openEditUser(user.userId)}
                            >
                              View
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan={4}>No users found</td>
                      </tr>
                    </tbody>
                  )}
                </Table>
              )}

              {[...Array(6)].map((_, index) => (
                <p key={index}>&nbsp;</p>
              ))}
            </div>
          </div>
        </div>
      </div>

      <UserModal
        open={userModalOpen}
        setOpen={setUserModalOpen}
        user={userModalData}
        onModify={updateUser}
        onSubmit={addUser}
      />
    </div>
  );
};
