import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Button, FormControl, Table, Image } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import HeroLogo from "../../assets/images/wow_hero_placeholder_operator.jpg";
import Open6gcLogo from "../../assets/images/bloxtel_icon.png";
import ApiHelper from "../../util/ApiHelper";
import Footer from "../Main/Footer";
import { useAppKitAccount } from "@reown/appkit/react";
import { ConnectButton } from "../../components/ui/ConnectButton";
import config from "../../config/config";
import Skeleton from "../../components/ui/Skeleton";

const Personalize = () => {
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [isWidthBigger, setIsWidthBigger] = useState(false);
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [height, setHeight] = useState("");
  const [geolocationLoading, setGeolocationLoading] = useState(true);
  const [geolocationError, setGeolocationError] = useState(false);
  const { address, isConnected } = useAppKitAccount();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);

  const updateWindowDimensions = useCallback(() => {
    setIsWidthBigger(
      (window.innerWidth * 0.67) / window.innerHeight > 650 / 525
    );
  }, []);

  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);

    getGeolocation();

    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, [updateWindowDimensions]);
  async function checkEnrollStatus() {
    const getEnrollStatus = await fetch(`${config.API_URL}/apenrollmenstatus`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await getEnrollStatus.json();
    if (data.isEnrolled === true && data.enrollmentInProgress === false) {
      toast.error("Already enrolled");
      setTimeout(() => {
        history.push("/");
      }, 2000);
    } else {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    setIsLoading(true);
    checkEnrollStatus();
  }, []);

  const getGeolocation = async () => {
    try {
      const response = await ApiHelper.getGeolocation();
      const { longitude, latitude, height } = response;

      setLongitude(longitude);
      setLatitude(latitude);
      setHeight(height);
      setGeolocationLoading(false);
    } catch (error) {
      setGeolocationLoading(false);
      setGeolocationError(true);
    }
  };

  const validateForm = () => {
    return "";
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      setErrorMsg(validateForm());
      return;
    }

    setSubmitDisabled(true);
    setErrorMsg("");

    console.log("address", address);

    const data = {
      longitude,
      latitude,
      height,
      walletAddress: address,
    };

    const response = await ApiHelper.startPersonalization(data);
    if (response) {
      console.log("Personalization request submitted successfully:", response);
      localStorage.setItem(
        "personalizationData",
        JSON.stringify(response.dataToBeSigned)
      );
      history.push("/enroll-sign");
    } else {
      console.error("Failed to submit personalization request");
    }

    setSubmitDisabled(false);
    setErrorMsg("");
  };

  const onClickBack = () => {
    history.push("/");
  };

  return (
    <div className="Login">
      <ToastContainer />
      <div className="HeroLogoContent">
        <Image
          src={HeroLogo}
          className={`${isWidthBigger ? "img-width" : "img-height"}`}
          alt="heroLogo"
        />
      </div>

      <div className="LoginFormContent">
        <div className="BackBtn" onClick={onClickBack}>
          <i className="fas fa-reply" />
        </div>
        {isLoading ? (
          <div className="flex justify-center items-center ">
            <Skeleton size="lg" className="mt-10 w-10 h-10" />
          </div>
        ) : (
          <div className="LoginForm">
            <img src={Open6gcLogo} alt="Bloxtel Operator dApp" />
            <h3>Enroll Access Point</h3>

            <div style={{ textAlign: "center", padding: "10px" }}>
              <p>
                Your geolocation will be retrieved automatically. Please wait a
                moment.
              </p>
              <p>
                Please provide the geolocation of your access point manually, if
                it takes too long.
              </p>
            </div>
            {!isConnected && (
              <div className="w-6/12 m-auto mt-4 mb-4">
                <ConnectButton />
              </div>
            )}

            <Table striped bordered hover>
              <tbody>
                <tr>
                  <td>Longitude:</td>
                  <td>
                    <FormControl
                      type="text"
                      placeholder="Format: 86.9250° E"
                      value={longitude}
                      onChange={(e) => setLongitude(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Latitude:</td>
                  <td>
                    <FormControl
                      type="text"
                      placeholder="Format: 27.9881° N"
                      value={latitude}
                      onChange={(e) => setLatitude(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Height:</td>
                  <td>
                    <FormControl
                      type="text"
                      placeholder="Format: 8,848 m"
                      value={height}
                      onChange={(e) => setHeight(e.target.value)}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>

            <span className="error-msg">
              <p>{errorMsg}</p>
            </span>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingTop: "20px",
              }}
            >
              <Button onClick={onClickBack}>Cancel</Button>

              <button
                type="submit"
                className={`rounded-md bg-[#7769f9] text-white px-4 py-2 cursor-pointer ${
                  submitDisabled || !isConnected ? "opacity-50" : ""
                }`}
                disabled={submitDisabled || !isConnected}
                onClick={handleSubmit}
              >
                Start Enrollment
              </button>
            </div>
          </div>
        )}
        <Footer />
      </div>
    </div>
  );
};

export default Personalize;
