import { RouteOffIcon } from "lucide-react";
import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const SubMenu = ({ item, location }) => {
  const [subnav, setSubnav] = useState(false);

  const showSubnav = () => setSubnav(!subnav);

  function handleDisabledClick() {
    toast.error("This feature is not available yet");
  }

  return (
    <>
      <div
        key={item.title}
        className={`sidebar-menu ${
          location.pathname === item.path
            ? "sidebar-menu__active"
            : "hover:rounded-3xl"
        }`}
        onClick={item.subNav && showSubnav}
      >
        {item.disabled ? (
          <div
            className="sidebar-menu__link cursor-not-allowed"
            onClick={handleDisabledClick}
          >
            <div className="title-panel">
              <div className="title-panel__icon">{item.icon}</div>
              <div className="title-panel__title">
                {item.title} <span className="text-red-500">(disabled)</span>
              </div>
            </div>
          </div>
        ) : (
          <Link
            className={`sidebar-menu__link ${
              location.pathname === item.path
                ? "sidebar-menu__link__active"
                : ""
            }`}
            to={item.subNav ? location.pathname : item.path}
          >
            <div className="title-panel">
              <div className="title-panel__icon">{item.icon}</div>
              <div className="title-panel__title">{item.title}</div>
            </div>
            <div>
              {item.subNav && subnav
                ? item.iconOpened
                : item.subNav
                ? item.iconClosed
                : null}
            </div>
          </Link>
        )}
      </div>
      {subnav &&
        item.subNav.map((item, index) => (
          <div
            key={item.title}
            className={`sidebar-menu sidebar-menu__sub  ${
              location.pathname === item.path ? "sidebar-menu__active" : ""
            }`}
            onClick={item.subNav && showSubnav}
          >
            <Link className="sidebar-menu__link" to={item.path}>
              <div className="title-panel">
                <div className="title-panel__icon">{item.icon}</div>
                <div className="title-panel__title">{item.title}</div>
              </div>
            </Link>
          </div>
        ))}
    </>
  );
};

export default withRouter(SubMenu);
