import * as React from "react";
import * as Sheet from "@radix-ui/react-dialog";
import { cn } from "../../lib/utils";
import { Button } from "../ui/Button";
import { PanelLeft, X } from "lucide-react";

export function AppSidebar({ children, sidebarOpened, setSidebarOpened }) {
  const [isMobileOpen, setIsMobileOpen] = React.useState(false);
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <>
      <Sheet.Root
        open={sidebarOpened}
        //  onOpenChange={setSidebarOpened}
        modal={false}
      >
        <Sheet.Portal>
          <Sheet.Overlay className="fixed inset-0 z-50 bg-transparent pointer-events-none" />
          <Sheet.Content className="fixed inset-y-0 left-0 z-50 h-full w-[300px] border-r bg-white p-0 shadow-lg">
            <div className="flex h-full flex-col">{children}</div>
          </Sheet.Content>
        </Sheet.Portal>
      </Sheet.Root>
    </>
  );
}

function useMediaQuery(query) {
  const [matches, setMatches] = React.useState(false);

  React.useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  }, [matches, query]);

  return matches;
}
