import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { SidebarData, SidebarDataSubscriber } from "./SidebarData";
import SubMenu from "./SubMenu";
import ApiHelper from "../../util/ApiHelper";

const Nav = () => {
  const [plan, setPlan] = useState("");
  const [status, setStatus] = useState("");

  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    const fetchOperatorData = async () => {
      try {
        const operator = await ApiHelper.fetchOperatorById(user.operatorId);
        setPlan(operator.subscriptionType);
        setStatus(operator.subscriptionStatus);
      } catch (error) {
        console.error("Failed to fetch operator data", error);
      }
    };

    if (user.operatorId) {
      fetchOperatorData();
    }
  }, [user.operatorId]);

  const filterSidebarItems = () => {
    if (user.role === "SUBSCRIBER") {
      return SidebarDataSubscriber;
    }

    return SidebarData.filter((item) => {
      if (user.role === "OPERATOR" && item.title === "Operators") return false;
      if (user.role === "Admin" && item.title === "Users") return false;
      if (user.role === "TECHNICIAN" && item.title === "Users") return false;
      if (
        user.role === "OPERATOR" &&
        plan === "Basic" &&
        item.title === "User Equipment"
      )
        return false;
      if (
        (user.role === "OPERATOR" &&
          plan !== "Advanced" &&
          item.title === "Payouts") ||
        (item.title === "Payouts" &&
          plan === "Advanced" &&
          status === "PENDING")
      ) {
        return false;
      }
      return true;
    });
  };

  const sidebarItems = filterSidebarItems();

  return (
    <ul className="nav mb-10">
      {sidebarItems.map((item) => (
        <SubMenu key={item.title} item={item} />
      ))}
    </ul>
  );
};

export default Nav;
