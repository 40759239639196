// src/pages/Dashboard/index.js
import { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import config from "../../config/config";
import { ErrorDisplay } from "../../components/ErrorDisplay";
import Skeleton from "../../components/ui/Skeleton";
import { toast } from "react-toastify";
import ApiHelper, { customFetch } from "../../util/ApiHelper";
import { useSelector } from "react-redux";

const StatusIndicator = ({ isActive, label }) => (
  <div className="d-flex align-items-center">
    <span
      className="status-indicator"
      style={{
        display: "inline-block",
        width: "12px",
        height: "12px",
        borderRadius: "50%",
        backgroundColor: isActive ? "#4CBB17" : "#CD5C5C",
        marginRight: "8px",
      }}
      title={isActive ? "Active" : "Inactive"}
    />
    {label}
  </div>
);

const NetworkStatus = () => {
  const [apStatus, setApStatus] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const currentUser = useSelector((state) => state.auth.user);
  console.log(currentUser, "currentUser");
  const fetchAPStatus = async () => {
    if (isInitialLoad) {
      setIsLoading(true);
    }

    try {
      const res = await ApiHelper.getApStatus();

      const data = await res.data;

      if (data) {
        setApStatus(data);
        setError(null);
      }
    } catch (err) {
      setError(err);
      console.log(err, "err");
      if (!isInitialLoad) {
        toast.error("Failed to fetch AP status", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setApStatus([]);
      }
    } finally {
      setIsInitialLoad(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAPStatus();
    const interval = setInterval(fetchAPStatus, 10000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="w-[94%] m-auto rounded-2xl mt-2 p-1">
      <div className="row">
        <div className="card">
          <div className="header overview__header">
            <div className="overview__searchbar">
              <h4>AP Status</h4>
            </div>
          </div>
          <div className="content overview__content">
            {isInitialLoad ? (
              <Skeleton />
            ) : (
              <Table
                className="overview__table"
                striped
                bordered
                responsive
                condensed
                hover
              >
                <thead>
                  <tr>
                    <th>APID</th>
                    <th>Number of UEs</th>
                    <th>Cell Status</th>
                    <th>GNSS Status</th>
                    <th>Core Status</th>
                  </tr>
                </thead>
                <tbody>
                  {apStatus.length > 0 ? (
                    apStatus.map((ap) => (
                      <tr key={ap.apid}>
                        <td>{ap.apid}</td>
                        <td>{ap.numUEs}</td>
                        <td>
                          <StatusIndicator
                            isActive={ap.connected}
                            label={ap.connected ? "Connected" : "Disconnected"}
                          />
                        </td>
                        <td>
                          <StatusIndicator
                            isActive={ap.gNSSConnected}
                            label={
                              ap.gNSSConnected ? "Available" : "Unavailable"
                            }
                          />
                        </td>
                        <td>
                          <StatusIndicator
                            isActive={ap.coreConnected}
                            label={
                              ap.coreConnected ? "Connected" : "Disconnected"
                            }
                          />
                        </td>
                      </tr>
                    ))
                  ) : error ? (
                    <ErrorDisplay error={error} />
                  ) : (
                    <tr>
                      <td colSpan="5" className="text-center">
                        No data available
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NetworkStatus;
