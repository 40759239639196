export const ErrorDisplay = ({ error }) => {
  if (!error) return null;

  const getErrorMessage = () => {
    if (typeof error === "string") return `⚠️ ${error}`;

    if (error.code && error.message) {
      return `⚠️ ${error.message} (Code: ${error.code})`;
    }

    return "⚠️ An unexpected error occurred";
  };

  return (
    <div
      className="alert alert-danger w-screen m-auto justify-content-center"
      role="alert"
    >
      {getErrorMessage()}
    </div>
  );
};
