import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Form from "@rjsf/core";
import PropTypes from "prop-types";
import { blockModalSchema } from "../../../metadata/index";
import { Table, Button } from "react-bootstrap";

class BlockModal extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    block: PropTypes.object,
  };

  state = {
    editMode: false,
    formData: undefined,
    // for force re-rendering json form
    rerenderCounter: 0,
    txs: [],
  };

  schema = blockModalSchema;

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      this.setState({ editMode: !!this.props.block });

      if (this.props.block) {
        const block = this.props.block;

        let formData = {
          blockId: block["id"],
          blockHash: block["hash"],
          transactionsCount: block["transactionsCount"],
        };

        this.updateFormData(formData).then();

        if (block.transactions[0]) {
          this.setState({ txs: block.transactions[0].tx_data });
          this.setState({ tx_id: block.transactions[0].tx_id });
        }
      } else {
        let formData = {
          blockId: "",
          blockHash: "",
          transactionsCount: "",
        };
        this.updateFormData(formData).then();
      }
    }
  }

  async onChange(data) {
    const lastData = this.state.formData;

    if (lastData && lastData.blockId === undefined) lastData.blockId = "";
  }

  async updateFormData(newData) {
    await this.setState({});
  }

  onSubmitClick(result) {
    const formData = result.formData;

    let blockData = {
      blockId: formData["blockId"],
      blockHash: formData["blockHash"],
      transactionsCount: formData["transactionsCount"],
    };

    if (this.state.editMode) {
      this.props.onModify(blockData);
    } else {
      this.props.onSubmit(blockData);
    }
  }

  render() {
    const scrollableTableStyle = {
      display: "block",
      maxHeight: "400px",
      overflowY: "auto",
      marginBottom: "10px",
    };

    const modalBodyStyle = {
      padding: "15px",
    };

    return (
      <Modal
        show={this.props.open}
        className={"block-modal theme-light"}
        backdrop={"static"}
        onHide={this.props.setOpen.bind(this, false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            View Block Details
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={modalBodyStyle}>
          <div style={scrollableTableStyle}>
            <Table
              className="overview__table"
              striped
              bordered
              condensed
              hover
              responsive
            >
              <thead>
                <tr>
                  <th>Action</th>
                  <th>From</th>
                  <th>To</th>
                  <th>Amount</th>
                  <th>Token ID</th>
                  <th style={{ width: 400 }}>Transaction ID</th>
                </tr>
              </thead>
              <tbody>
                {this.state.txs.map((action) => {
                  // Determine the action based on from and to addresses
                  let actionType = "Transfer"; // Default action
                  if (action.from === "0x0") {
                    actionType = "Mint";
                  } else if (action.to === "0x0") {
                    actionType = "Burn";
                  }

                  return (
                    <tr key={action.id}>
                      <td>{actionType}</td> {/* Display the computed Action */}
                      <td>{action.from}</td>
                      <td>{action.to}</td>
                      <td>{action.value}</td>
                      <td>{action.id}</td>
                      <td>{this.state.tx_id}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default BlockModal;
