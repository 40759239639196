import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Nav from "./Nav";
import Bloxteldapplogo from "../../assets/images/bloxtel_icon.png";

function SideBarMobile(props) {
  const { sidebarOpened, setSidebarOpened, user } = props;

  const onSidebarClose = () => {
    setSidebarOpened(false);
  };

  const onSidebarOpen = () => {
    setSidebarOpened(true);
  };

  return (
    <div
      className={`fixed top-0 left-0 h-screen w-full bg-black bg-opacity-70 z-50 transition-transform transform z-100 ${
        sidebarOpened ? "translate-x-0" : "-translate-x-full"
      } md:hidden flex flex-col items-center justify-center`}
    >
      <button
        onClick={onSidebarClose}
        className="absolute top-4 right-4 text-white text-2xl focus:outline-none"
      >
        <i className="fas fa-times" />
      </button>

      <div className="mb-8">
        <img src={Bloxteldapplogo} alt="logo" className="h-16 w-16 mb-2" />
        <h1 className="text-white text-2xl font-bold">
          {user.role === "SUBSCRIBER" ? "Subscriber dApp" : "Operator dApp"}
        </h1>
      </div>

      <div className="w-full">
        <Nav />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default withRouter(connect(mapStateToProps)(SideBarMobile));
