import React, { Component } from "react";
import { connect } from "react-redux";
import { toggleMobileNavVisibility } from "../../redux/reducers/layout";
// import { Nav, Navbar, NavItem } from "react-bootstrap";
import { Navbar } from "../../components/Navbar";
import { withRouter } from "react-router-dom";
import AuthHelper from "../../util/AuthHelper";
import Profile from "../../components/Profile/Profile";

class Header extends Component {
  static async logout() {
    await AuthHelper.logout();
  }

  render() {
    return <Navbar />;
  }
}

const mapDispatchToProp = (dispatch) => ({
  toggleMobileNavVisibility: () => dispatch(toggleMobileNavVisibility()),
});

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProp)(Header));
