import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Table } from "react-bootstrap";
import SubscriberModal from "./components/SubscriberModal";
import ApiHelper from "../../util/ApiHelper";
import AppUtils, { formatDate } from "../../util/AppUtils";
import SearchBar from "../../components/FormInputs/SearchBar.js";
import { ethers, Signature } from "ethers";
import Skeleton from "../../components/ui/Skeleton";
import { signTypedDataMessage } from "../../util/blockchainUtils";
class SubscriberOverview extends Component {
  state = {
    subscriberModalOpen: false,
    subscriberModalData: null,
    searchString: "",
  };

  componentDidMount() {
    ApiHelper.fetchSubscribers().then();
  }

  openAddSubscriber() {
    this.setState({
      subscriberModalOpen: true,
      subscriberModalData: null,
    });
  }

  /**
   * @param userId  {string}
   */
  async openEditSubscriber(userId) {
    console.log("subscribers", this.props.subscribers);
    const subscriber = await ApiHelper.fetchSubscriberById(userId);

    this.setState({
      subscriberModalOpen: true,
      subscriberModalData: subscriber,
    });
  }

  async addSubscriber(operatorData) {
    // this.setState({ subscriberModalOpen: false });
    // let result = await ApiHelper.createOperator(operatorData);
    // if (result.status === 200) {
    //   const adminData = {
    //     email: operatorData["email"],
    //     password: operatorData["password"],
    //     role: "ADMIN",
    //   }
    //   const adminResult = await ApiHelper.createUser(result.data.operatorId, adminData);
    //   if (!adminResult) {
    //     alert('Eror in creating default admin user.');
    //   }
    // } else {
    //   alert("Error creating new operator");
    // }
    // ApiHelper.fetchOperators().then();
  }

  /**
   * @param subscriberData
   */
  async updateSubscriber(subscriberData) {
    this.setState({ subscriberModalOpen: false });

    const result = await ApiHelper.updateSubscriber(subscriberData);

    if (!result) {
      alert("Error updating subscriber: " + subscriberData["userId"]);
    }
    ApiHelper.fetchSubscribers().then();
  }

  /**
   * @param subscriber  {Subscriber}
   */
  async deleteSubscriber(subscriber) {
    if (!window.confirm(`Delete operator ${subscriber.id}?`)) return;

    const result = await ApiHelper.deleteSubscriber(subscriber.id);
    ApiHelper.fetchSubscribers().then();
    if (!result) {
      alert("Error deleting subscriber: " + subscriber.id);
    }
  }

  async signMessage(orderId, amount) {
    const deploymentInfo = await ApiHelper.getDeploymentInfo("BloxtelManager");
    if (!deploymentInfo) {
      console.error("Failed to get deployment info");
      return;
    }

    const BLOXTEL_MANAGER_ADDRESS = deploymentInfo.address;
    const CHAIN_ID = deploymentInfo.chainId;

    // Define the types for EIP712 signing
    const types = {
      EIP712Domain: [
        { name: "name", type: "string" },
        { name: "version", type: "string" },
        { name: "chainId", type: "uint256" },
        { name: "verifyingContract", type: "address" },
      ],
      MintdSIMFT: [
        { name: "orderId", type: "string" },
        { name: "amount", type: "uint256" },
        // { name: "description", type: "string" },
      ],
    };

    if (!window.ethereum) {
      alert("MetaMask not detected. Please install MetaMask.");
      return;
    }

    try {
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();

      // Prepare the message to sign using the encoded function call and nonce
      const message = {
        orderId: orderId,
        amount: amount,
        description: `Authorize the minting of ${amount} dSIM FT tokens`,
      };

      // Define the EIP712 domain (handled from .env)
      const domain = {
        name: "BloxtelManager",
        version: "1",
        chainId: parseInt(CHAIN_ID), // Chain ID from .env
        verifyingContract: BLOXTEL_MANAGER_ADDRESS, // EntryPoint address from .env
      };

      // Prepare the typed data for signing
      const typedData = {
        domain,
        types,
        primaryType: "MintdSIMFT", // This is key to avoid ambiguity
        message,
      };

      // const params = [await signer.getAddress(), JSON.stringify(typedData)];
      // const signature = await window.ethereum.request({
      //   method: "eth_signTypedData_v4",
      //   params: params,
      //   from: await signer.getAddress(),
      // });

      const signature = await signTypedDataMessage(
        domain,
        types,
        message,
        await signer.getAddress(),
        window.ethereum
      );

      const parsedSignature = Signature.from(signature);

      // Prepare the data to send to the backend
      const requestData = {
        from: await signer.getAddress(),
        orderId: orderId,
        amount: amount,
        v: parsedSignature.v,
        r: parsedSignature.r,
        s: parsedSignature.s,
      };

      // Send the data to the backend
      const response = await ApiHelper.postMintFtRequest(requestData);
      if (response) {
        console.log("Mint request submitted successfully:", response);
      } else {
        console.error("Failed to submit mint request");
      }

      ApiHelper.fetchOrders()
        .then((orders) => {
          this.getOrdersStatus(orders);
        })
        .catch((error) => {
          console.error("Error fetching orders:", error);
        });
    } catch (error) {
      console.error("Error signing message", error);
    }
  }

  onChangeSearch(event) {
    this.setState({ searchString: event.target.value });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header overview__header">
                <div className="overview__searchbar">
                  <h4>Subscribers</h4>
                  <div className="overview__searchpanel">
                    <SearchBar
                      value={this.state.searchString}
                      onChange={this.onChangeSearch.bind(this)}
                    />
                  </div>
                </div>
                <Button
                  bsStyle={"primary"}
                  className="overview__button"
                  onClick={this.openAddSubscriber.bind(this)}
                >
                  New Subscriber
                </Button>
              </div>
              <div className="content overview__content">
                {this.props.subscribers.length === 0 ? (
                  <Skeleton />
                ) : (
                  <Table
                    className="overview__table"
                    striped
                    bordered
                    condensed
                    hover
                  >
                    <thead>
                      <tr>
                        <th style={{ width: 400 }}>ID</th>
                        <th>Name</th>
                        <th>Created</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.subscribers
                        .filter((subscriber) =>
                          subscriber.id.includes(this.state.searchString)
                        )
                        .map((subscriber) => (
                          <tr key={subscriber.id}>
                            <td>{subscriber.id}</td>
                            <td>{subscriber.name}</td>
                            <td>{formatDate(subscriber.createdDate)}</td>
                            <td>
                              <span
                                style={{
                                  color: AppUtils.getStatusTextColor(
                                    subscriber.status
                                  ),
                                }}
                              >
                                {subscriber.status}
                              </span>
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Button
                                variant="danger"
                                onClick={this.deleteSubscriber.bind(
                                  this,
                                  subscriber
                                )}
                              >
                                Delete
                              </Button>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <Button
                                variant="info"
                                onClick={this.openEditSubscriber.bind(
                                  this,
                                  subscriber.id
                                )}
                              >
                                View
                              </Button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                )}

                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
              </div>
            </div>
          </div>
        </div>

        <SubscriberModal
          open={this.state.subscriberModalOpen}
          setOpen={(val) => this.setState({ subscriberModalOpen: val })}
          subscriber={this.state.subscriberModalData}
          onModify={this.updateSubscriber.bind(this)}
          onSubmit={this.addSubscriber.bind(this)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  subscribers: state.subscriber.subscribers,
});

export default withRouter(connect(mapStateToProps)(SubscriberOverview));
