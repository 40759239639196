import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Image } from "react-bootstrap";
import Footer from "../Main/Footer";
import HeroLogo from "../../assets/images/wow_hero_placeholder_operator.jpg";
import Open6gcLogo from "../../assets/images/bloxtel_icon.png";
import { Spinner } from "@patternfly/react-core";
import { toast } from "react-toastify";
import config from "../../config/config";
const EnrollLoading = () => {
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [isWidthBigger, setIsWidthBigger] = useState(false);
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [height, setHeight] = useState("");
  const [geolocationLoading, setGeolocationLoading] = useState(true);
  const [geolocationError, setGeolocationError] = useState(false);
  const [isEnrolled, setIsEnrolled] = useState(false);
  const [isEnrollmentInProgress, setIsEnrollmentInProgress] = useState(false);

  const history = useHistory();

  useEffect(() => {
    const updateWindowDimensions = () => {
      setIsWidthBigger(
        (window.innerWidth * 0.67) / window.innerHeight > 650 / 525
      );
    };

    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);

    getGeolocation();
    setSubmitDisabled(true);

    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function checkEnrollStatus() {
    const getEnrollStatus = await fetch(`${config.API_URL}/apenrollmenstatus`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await getEnrollStatus.json();
    setIsEnrolled(data.isEnrolled);
    setIsEnrollmentInProgress(data.enrollmentInProgress);
  }

  setInterval(() => checkEnrollStatus(), 5000);

  const getGeolocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLongitude(position.coords.longitude);
          setLatitude(position.coords.latitude);
          setGeolocationLoading(false);

          if (isEnrolled === true && isEnrollmentInProgress === false) {
            toast.success("Successfully enrolled");
            setTimeout(() => {
              history.push("/");
            }, 2000);
          }
        },
        () => {
          setGeolocationLoading(false);
          setGeolocationError(true);
        }
      );
    } else {
      setGeolocationLoading(false);
      setGeolocationError(true);
    }
  };

  useEffect(() => {
    if (isEnrolled === true && isEnrollmentInProgress === false) {
      setTimeout(() => {
        toast.success("Successfully enrolled");
        history.push("/");
      }, 2000);
    }
  }, [isEnrolled, isEnrollmentInProgress]);

  const validateForm = () => {
    return "";
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const validationError = validateForm();

    if (validationError) {
      setErrorMsg(validationError);
      return;
    }
    setSubmitDisabled(true);
    setErrorMsg("");
  };

  const onClickBack = () => {
    history.push("/");
  };

  return (
    <div className="Login">
      <div className="HeroLogoContent">
        <Image
          src={HeroLogo}
          className={isWidthBigger ? "img-width" : "img-height"}
          alt="heroLogo"
        />
      </div>

      <div className="LoginFormContent">
        <div className="BackBtn" onClick={onClickBack}>
          <i className="fas fa-reply" />
        </div>
        <div className="LoginForm">
          <img src={Open6gcLogo} alt="Bloxtel Operator dApp" />
          <h3>Enroll Access Point</h3>

          <div style={{ textAlign: "center", padding: "10px" }}>
            <p>Your Access Point is being personalized. Please wait.</p>
          </div>

          <span className="error-msg">
            <p>{errorMsg}</p>
          </span>
          <Spinner animation="border" className="m-auto" variant="primary" />
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default EnrollLoading;
