import React from "react";
import { Table } from "react-bootstrap";

const ChargingList = ({
  expand,
  chargingData,
  chargingRecord,
  chargingMethod,
}) => {
  console.error("charging data", chargingData);
  const tableColumnNames = [
    "IMSI",
    "S-NSSAI",
    "DNN",
    "IP Filter",
    chargingMethod === "Online" ? "Quota" : "Usage",
    "Data Total Volume",
    "Data Volume UL",
    "Data Volume DL",
  ];

  const FlowUsageCell = ({ supi, dnn, snssai, filter }) => {
    const chargingRecordMatch = chargingRecord.find(
      (a) =>
        a.Supi === supi &&
        a.Dnn === dnn &&
        a.Snssai === snssai &&
        a.Filter === filter
    );

    return (
      <>
        <td>
          {chargingRecordMatch
            ? chargingMethod === "Online"
              ? chargingRecordMatch.QuotaLeft
              : chargingRecordMatch.Usage
            : "-"}
        </td>
        <td>{chargingRecordMatch ? chargingRecordMatch.TotalVol : "-"}</td>
        <td>{chargingRecordMatch ? chargingRecordMatch.UlVol : "-"}</td>
        <td>{chargingRecordMatch ? chargingRecordMatch.DlVol : "-"}</td>
      </>
    );
  };

  const PerFlowTableView = ({ Supi, Snssai }) => {
    if (!expand) return <></>;

    return (
      <>
        {chargingData
          .filter(
            (a) => a.filter !== "" && a.ueId === Supi && a.snssai === Snssai
          )
          .map((cd, idx) => (
            <tr key={idx}>
              <td></td>
              <td>{cd.snssai}</td>
              <td>{cd.dnn}</td>
              <td>{cd.filter}</td>
              {
                <FlowUsageCell
                  supi={Supi}
                  dnn={cd.dnn}
                  snssai={Snssai}
                  filter={cd.filter}
                />
              }
            </tr>
          ))}
      </>
    );
  };

  return (
    <div className="content overview__content">
      <h4>{chargingMethod} Charging</h4>
      <Table
        className="overview__table"
        striped
        bordered
        condensed
        hover
        responsive
      >
        <thead>
          <tr>
            {tableColumnNames.map((colName, idx) => {
              return <th key={idx}>{colName}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {chargingData
            .filter((a) => a.filter === "" && a.dnn === "")
            .map((cd, idx) => (
              <>
                <tr key={idx}>
                  <td>{cd.ueId ? cd.ueId.replace("imsi-", "") : ""}</td>
                  <td>{cd.snssai}</td>
                  <td>{cd.dnn}</td>
                  <td>{cd.filter}</td>
                  <FlowUsageCell
                    supi={cd.ueId}
                    dnn={cd.dnn}
                    snssai={cd.snssai}
                    filter={cd.filter}
                  />
                </tr>
                {<PerFlowTableView Supi={cd.ueId} Snssai={cd.snssai} />}
              </>
            ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ChargingList;
