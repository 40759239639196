import Http from "./Http";
import { store } from "../index";
import dsimActions from "../redux/actions/dsimActions";
import dSIM from "../models/dSIM";
import operatorActions from "../redux/actions/operatorActions";
import Operator from "../models/Operator";
import subscriberActions from "../redux/actions/subscriberActions";
import Subscriber from "../models/Subscriber";
import gatewayActions from "../redux/actions/gatewayActions";
import Gateway from "../models/Gateway";
import cellActions from "../redux/actions/cellActions";
import Cell from "../models/Cell";
import orderActions from "../redux/actions/orderActions";
import Order from "../models/Order";
import phoneActions from "../redux/actions/phoneActions";
import Phone from "../models/Phone";
import cameraActions from "../redux/actions/cameraActions";
import Camera from "../models/Camera";
import planActions from "../redux/actions/planActions";
import Plan from "../models/Plan";
import payoutActions from "../redux/actions/payoutActions";
import Payout from "../models/Payout";
import walletActions from "../redux/actions/walletActions";
import Wallet from "../models/Wallet";
import invoiceActions from "../redux/actions/invoiceActions";
import Invoice from "../models/Invoice";
import blockActions from "../redux/actions/blockActions";
import Block from "../models/Block";
import userActions from "../redux/actions/userActions";
import requestActions from "../redux/actions/requestActions";
import User from "../models/User";
import axios from "axios";
import LocalStorageHelper from "./LocalStorageHelper";
import { toast } from "react-toastify";
import config from "../config/config";

class ApiHelper {
  static async getRequest(url, responseHandler) {
    console.info(`HTTP GET request URL ${url} ...`);
    try {
      let response = await Http.get(url);
      console.debug(`HTTP response status for URL ${url}: ${response.status}`);
      if (response.status === 200) {
        store.dispatch(requestActions.requestSuccess());
        if (responseHandler) {
          responseHandler(response);
        }
        return response.data;
      }
      let error = new Error(
        `HTTP GET response error with status: ${response.status} and data: ${response.data}`
      );
      console.error(`HTTP GET status error for URL ${url}`, error);
      store.dispatch(requestActions.requestFailed(error));
    } catch (error) {
      console.error(`HTTP GET request error for URL ${url}`, error);
      store.dispatch(requestActions.requestFailed(error));
    }
    return null;
  }

  static async fetchChargingRecord() {
    console.log("-->ApiHelper :: fetchChargingRecord()");
    try {
      let response = await Http.get(`charging-record`);
      if (response.status === 200 && response.data) {
        return response.data;
      }
    } catch (error) {
      console.log(
        "ApiHelper :: fetchChargingRecord() ERROR:: error = " + error
      );
      console.error(error);
    }
    return false;
  }

  static async fetchChargingData(chargingMethod) {
    console.log("-->ApiHelper :: fetchChargingData()");
    try {
      let response = await Http.get(`charging-data/${chargingMethod}`);
      if (response.status === 200 && response.data) {
        return response.data;
      }
    } catch (error) {
      console.log("ApiHelper :: fetchChargingData() ERROR:: error = " + error);
      console.error(error);
    }
    return false;
  }
  //<editor-fold defaultstate="collapsed" desc="sims">

  static async fetchdSIMs() {
    try {
      console.log("-->ApiHelper :: fetchdSIMs()");
      store.dispatch(dsimActions.setdSIMs([])); // Clear previous data
      const response = await Http.get(`dsim`); // Make the API call
      if (response.status === 200 && response.data) {
        const dsims = response.data.map(
          (val) =>
            new dSIM(
              val["dsimId"],
              val["servingPlmnId"],
              val["iccid"],
              val["imsi"],
              val["dsimType"],
              val["authMethod"],
              val["createdDate"],
              val["modifiedDate"],
              val["orderId"]
            )
        );
        store.dispatch(dsimActions.setdSIMs(dsims)); // Dispatch to the store
        return dsims; // Return the dSIMs array
      }
    } catch (error) {
      console.error(error); // Log any errors
    }

    return []; // Return an empty array in case of an error
  }

  static async fetchdSIMById(id) {
    console.log("-->ApiHelper :: fetchdSIMById()");
    try {
      let response = await Http.get(`dsim/${id}`);
      if (response.status === 200 && response.data) {
        return response.data;
      }
    } catch (error) {
      console.log("ApiHelper :: fetchdSIMById() ERROR:: error = " + error);
      console.error(error);
    }
    return false;
  }

  static async createdSIMBatch(dsimBatchData) {
    try {
      console.log("-->ApiHelper :: createdSIMBatch()");
      console.log("dsimBatchData = " + JSON.stringify(dsimBatchData));
      let response = await Http.post(
        `dsim/batch/${dsimBatchData["orderId"]}`,
        dsimBatchData
      );
      console.log("createdSIMBatch response.status = " + response.status);
      if (response.status === 201) {
        return true;
      }
    } catch (error) {
      console.log("ApiHelper :: createdSIMBatch() ERROR:: error = " + error);
      console.error(error);
    }

    return false;
  }

  static async updatedSIM(dsimData) {
    try {
      let response = await Http.put(`dsim/${dsimData["dsimId"]}`, dsimData);
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async transferdSIM(dsimId, transferData, blockchainAddress) {
    try {
      console.log("-->ApiHelper :: transferdSIM ()");

      let response = await Http.post(`dsim/transfer/${dsimId}`, transferData);
      console.log("transferdSIM response.status = " + response.status);

      if (response.status === 200) {
        return true;
      }
    } catch (error) {
      console.log("ApiHelper :: createdSIM() ERROR:: error = " + error);

      console.error(error);
    }

    return false;
  }

  //</editor-fold>

  static async login(loginRequest, isSubscriber) {
    console.log("ApiHelper :: login()", isSubscriber);
    try {
      let response = await Http.post(
        `${isSubscriber ? "loginsubscriber" : "login"}`,
        loginRequest
      );
      return response;
    } catch (error) {
      console.error(error);
      return error.response;
    }
  }

  static async forgotPassword(request) {
    console.log("ApiHelper :: ForgotPassword()");
    try {
      let response = await Http.post(`forgotpassword`, request);
      return response;
    } catch (error) {
      console.error(error);
      return error.response;
    }
  }

  static async resetPassword(request) {
    console.log("ApiHelper :: ResetPassword()");
    try {
      let response = await Http.post(`resetpassword`, request);
      return response;
    } catch (error) {
      console.error(error);
      return error.response;
    }
  }

  static async fetchNotificaitons() {
    // try {
    //   let response = await Http.get('notification');
    //   if (response.status === 200 && response.data) {
    //     return response.data;
    //   }
    // } catch (error) {
    //   console.error(error);
    // }

    return false;
  }

  static async fetchNotificationById(id) {
    // try {
    //   let response = await Http.get(`notification/${id}`);
    //   if (response.status === 200 && response.data) {
    //     return response.data;
    //   }
    // } catch (error) {
    // }

    return false;
  }

  static async createNotification(notificationData) {
    try {
      let response = await Http.post("notification", notificationData);
      return response;
    } catch (error) {
      console.error(error);
      return error.response;
    }
  }

  static async updateNotification(notificationData) {
    try {
      let response = await Http.put(
        `notification/${notificationData["notificationId"]}`,
        notificationData
      );
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async deleteNotification(id) {
    try {
      let response = await Http.delete(`notification/${id}`);
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async fetchOperators() {
    try {
      store.dispatch(operatorActions.setOperators([]));
      let response = await Http.get("operator");
      if (response.status === 200 && response.data) {
        const operators = response.data.map(
          (val) =>
            new Operator(
              val["operatorId"],
              val["businessName"],
              val["operatorLogo"],
              val["subscriptionType"],
              val["subscriptionStatus"],
              val["status"],
              val["createdDate"]
            )
        );
        store.dispatch(operatorActions.setOperators(operators));
        return true;
      }
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async fetchOperatorById(id) {
    return (await this.getRequest(`operator/${id}`)) || false;
  }

  static async createOperator(operatorData) {
    try {
      let response = await Http.post("operator", operatorData);
      return response;
    } catch (error) {
      console.error(error);
      return error.response;
    }
  }

  static async updateOperator(operatorData) {
    try {
      let response = await Http.put(
        `operator/${operatorData["operatorId"]}`,
        operatorData
      );
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async deleteOperator(id) {
    try {
      let response = await Http.delete(`operator/${id}`);
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async fetchSubscribers() {
    try {
      store.dispatch(subscriberActions.setSubscribers([]));
      let response = await Http.get("subscriber");
      if (response.status === 200 && response.data) {
        const subscribers = response.data.map(
          (val) =>
            new Subscriber(
              val["userId"],
              val["firstName"] + " " + val["lastName"],
              val["subscriberLogo"],
              val["status"],
              val["createdDate"]
            )
        );
        store.dispatch(subscriberActions.setSubscribers(subscribers));
        return true;
      }
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async getApStatus() {
    try {
      let user = LocalStorageHelper.getUserInfo();
      axios.defaults.headers.common["Token"] = user.accessToken;
      let response = await Http.get("apStatus");
      return response;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async getSubscribers() {
    try {
      let user = LocalStorageHelper.getUserInfo();
      axios.defaults.headers.common["Token"] = user.accessToken;
      let response = await Http.get("subscriber");
      if (response.status === 200 && response.data) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async fetchSubscriberById(id) {
    try {
      let response = await Http.get(`subscriber/${id}`);
      if (response.status === 200 && response.data) {
        return response.data;
      }
    } catch (error) {}

    return false;
  }

  static async createSubscriber(subscriberData) {
    try {
      let response = await Http.post("subscriber", subscriberData);
      return response;
    } catch (error) {
      console.error(error);
      return error.response;
    }
  }

  static async updateSubscriber(subscriberData) {
    try {
      let response = await Http.put(
        `subscriber/${subscriberData["userId"]}`,
        subscriberData
      );
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async deleteSubscriber(id) {
    try {
      let response = await Http.delete(`subscriber/${id}`);
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async fetchUsers(operatorId) {
    try {
      store.dispatch(userActions.setUsers([]));
      let response = await Http.get(`operator/${operatorId}/user`);
      if (response.status === 200 && response.data) {
        const users = response.data.map(
          (val) =>
            new User(
              "",
              val["firstName"],
              val["lastName"],
              val["access_token"],
              val["userId"],
              val["email"],
              val["operatorId"],
              val["role"],
              val["status"]
            )
        );

        store.dispatch(userActions.setUsers(users));
        return true;
      }
    } catch (error) {}

    return false;
  }

  static async fetchUsersByOperatorId(operatorId) {
    try {
      let response = await Http.get(`operator/${operatorId}/user`);
      return response;
    } catch (error) {
      console.error(error);
      return error.response;
    }
  }

  static async fetchUserById(operatorId, id) {
    try {
      let response = await Http.get(`operator/${operatorId}/user/${id}`);
      if (response.status === 200 && response.data) {
        return response.data;
      }
    } catch (error) {}

    return false;
  }

  static async createUser(operatorId, userData) {
    try {
      userData["encryptedPassword"] = userData["password"];
      let response = await Http.post(`operator/${operatorId}/user`, userData);
      if (response.status === 201) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async updateUser(operatorId, userId, userData) {
    try {
      userData["encryptedPassword"] = userData["password"];
      let response = await Http.put(
        `operator/${operatorId}/user/${userId}`,
        userData
      );
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async deleteUser(operatorId, id) {
    try {
      let response = await Http.delete(`operator/${operatorId}/user/${id}`);
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  //**********************************************************************
  //*********************** ACCESS GATEWAYS ******************************
  //**********************************************************************
  static async fetchGateways(operatorId) {
    try {
      console.log("-->ApiHelper :: fetchGateways ()");
      store.dispatch(gatewayActions.setGateways([]));
      let response = await Http.get(`operator/${operatorId}/gateway`);
      if (response.status === 200 && response.data) {
        const gateways = response.data.map(
          (val) =>
            new Gateway(
              val["gatewayId"],
              val["gatewayName"],
              val["gatewayIp"],
              val["latitude"],
              val["longitude"],
              val["status"],
              val["createdDate"]
            )
        );
        store.dispatch(gatewayActions.setGateways(gateways));
        return true;
      }
    } catch (error) {}

    return false;
  }

  static async fetchGatewayById(operatorId, id) {
    try {
      let response = await Http.get(`operator/${operatorId}/gateway/${id}`);
      if (response.status === 200 && response.data) {
        return response.data;
      }
    } catch (error) {}

    return false;
  }

  static async createGateway(operatorId, gatewayData) {
    try {
      let response = await Http.post(
        `operator/${operatorId}/gateway`,
        gatewayData
      );
      if (response.status === 201) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async updateGateway(operatorId, gatewayData) {
    try {
      let response = await Http.put(
        `operator/${operatorId}/gateway/${gatewayData["gatewayId"]}`,
        gatewayData
      );
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async deleteGateway(operatorId, id) {
    try {
      let response = await Http.delete(`operator/${operatorId}/gateway/${id}`);
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async getRemoteLoggingServerIP() {
    try {
      let response = await Http.get(`logServerIP`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async getMapServerIP() {
    try {
      let response = await Http.get(`mapServerIP`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async getPricePerGB() {
    try {
      let response = await Http.get(`pricePerGB`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async getMapHexagonRadius() {
    try {
      let response = await Http.get(`mapHexagonRadius`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async getRefreshTime() {
    try {
      let response = await Http.get(`refreshTime`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async fetchMeasurements() {
    try {
      let response = await Http.get(`measurements`);
      if (response.status === 200 && response.data) {
        return response.data;
      }
    } catch (error) {}

    return false;
  }

  static async fetchCameraEvents() {
    try {
      let response = await Http.get(`cameraEvents`);
      if (response.status === 200 && response.data) {
        return response.data;
      }
    } catch (error) {}

    return false;
  }

  static async getFrimwareDevices() {
    try {
      let response = await Http.get(`firmwareDevices`);
      if (response.status === 200 && response.data) {
        return response.data;
      }
    } catch (error) {
      console.error("error", error);
    }

    return false;
  }

  static async fetchCameraActions() {
    try {
      let response = await Http.get(`cameraActions`);
      if (response.status === 200 && response.data) {
        return response.data;
      }
    } catch (error) {}

    return false;
  }

  static async getRTMPServerURL() {
    try {
      let response = await Http.get(`rtmpServerUrl`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async getFirmwareDevices() {
    try {
      let response = await Http.get(`firmwareDevices`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async getCameraLogging(baseUrl) {
    try {
      const response = await fetch(`${baseUrl}/logging`, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      });
      const result = await response.json();

      if (response.status === 200) return result;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async getCameraHeatmapData(baseUrl) {
    try {
      const response = await fetch(`${baseUrl}/heatmap`, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      });
      const result = await response.json();

      if (response.status === 200) return result;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async getAimacServerIP() {
    try {
      let response = await Http.get(`aimacServerIP`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async getRTMPDefaultChannel() {
    try {
      let response = await Http.get(`rtmpDefaultChannel`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async getRegisterIP() {
    try {
      let response = await Http.get(`registerIP`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async getVerifierIP() {
    try {
      let response = await Http.get(`verifierIP`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  //**********************************************************************
  //*********************** Small Cells **********************************
  //**********************************************************************
  static async fetchCells(operatorId) {
    try {
      console.log("-->ApiHelper :: fetchCells ()");
      store.dispatch(cellActions.setCells([]));
      let response = await Http.get(`operator/${operatorId}/cell`);
      if (response.status === 200 && response.data) {
        const cells = response.data.map(
          (val) =>
            new Cell(
              val["uuID"],
              val["plmn"],
              val["lac"],
              val["cellID"],
              val["radioType"],
              val["cellBand"],
              val["latitude"],
              val["longitude"],
              val["status"],
              val["createdDate"]
            )
        );
        store.dispatch(cellActions.setCells(cells));
        return true;
      }
    } catch (error) {}

    return false;
  }

  static async fetchCellsForMap(operatorId) {
    try {
      console.log("-->ApiHelper :: fetchCellsForMap ()");
      store.dispatch(cellActions.setCells([]));
      let response = await Http.get(`operator/${operatorId}/mapCell`);

      if (response.status === 200 && response.data) {
        return response.data;
      }
    } catch (error) {
      console.error("Getting error on fetchCellsForMap()", error);
    }

    return false;
  }

  static async fetchCellById(operatorId, id) {
    try {
      let response = await Http.get(`operator/${operatorId}/cell/${id}`);
      if (response.status === 200 && response.data) {
        return response.data;
      }
    } catch (error) {}

    return false;
  }

  static async createCell(operatorId, cellData) {
    try {
      let response = await Http.post(`operator/${operatorId}/cell`, cellData);
      if (response.status === 201) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async updateCell(operatorId, cellData) {
    try {
      let response = await Http.put(
        `operator/${operatorId}/cell/${cellData["uuID"]}`,
        cellData
      );
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async deleteCell(operatorId, id) {
    try {
      let response = await Http.delete(`operator/${operatorId}/cell/${id}`);
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  //<editor-fold defaultstate="collapsed" desc="orders">
  static async fetchOrders() {
    try {
      console.log("-->ApiHelper :: fetchOrders ()");
      store.dispatch(orderActions.setOrders([]));
      let response = await Http.get(`order`);
      if (response.status === 200 && response.data) {
        const orders = response.data.map(
          (val) =>
            new Order(
              val["orderId"],
              val["name"],
              val["orderType"],
              val["orderQuantity"],
              val["status"],
              val["createdDate"],
              val["modifiedDate"],
              val["dsimType"],
              val["authMethod"]
            )
        );
        store.dispatch(orderActions.setOrders(orders));
        return orders;
      }
    } catch (error) {
      console.error(error);
    }

    return [];
  }

  static async getApEnrollmentStatus() {
    try {
      let response = await Http.get("apenrollmenstatus");
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  static async fetchOrderById(id) {
    try {
      let response = await Http.get(`order/${id}`);
      if (response.status === 200 && response.data) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async createOrder(orderData) {
    try {
      let response = await Http.post(`order`, orderData);

      console.log("response", response);

      if (response.status === 201) {
        return true;
      }
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async updateOrder(orderData) {
    const response = await fetch(`/api/orders/${orderData.id}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(orderData),
    });
    return response.ok;
  }

  //</editor-fold>

  //**********************************************************************
  //*********************** PHONES ***************************************
  //**********************************************************************
  static async fetchPhones(operatorId) {
    try {
      console.log("-->ApiHelper :: fetchPhones ()");
      store.dispatch(phoneActions.setPhones([]));
      let response = await Http.get(`operator/${operatorId}/phone`);
      if (response.status === 200 && response.data) {
        const phones = response.data.map(
          (val) =>
            new Phone(
              val["uuId"],
              val["imei"],
              val["manufacturerName"],
              val["modelName"],
              val["createdDate"]
            )
        );
        store.dispatch(phoneActions.setPhones(phones));
        return true;
      }
    } catch (error) {}

    return false;
  }

  static async fetchPhoneById(operatorId, id) {
    try {
      let response = await Http.get(`operator/${operatorId}/phone/${id}`);
      if (response.status === 200 && response.data) {
        return response.data;
      }
    } catch (error) {}

    return false;
  }

  static async createPhone(operatorId, phoneData) {
    try {
      let response = await Http.post(`operator/${operatorId}/phone`, phoneData);
      if (response.status === 201) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async updatePhone(operatorId, phoneData) {
    try {
      let response = await Http.put(
        `operator/${operatorId}/phone/${phoneData["uuId"]}`,
        phoneData
      );
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async deletePhone(operatorId, id) {
    try {
      let response = await Http.delete(`operator/${operatorId}/phone/${id}`);
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  //**********************************************************************
  //*********************** CAMERAS ***************************************
  //**********************************************************************
  static async fetchCameras(operatorId) {
    try {
      console.log("-->ApiHelper :: fetchCameras ()");
      store.dispatch(cameraActions.setCameras([]));
      let response = await Http.get(`operator/${operatorId}/camera`);
      if (response.status === 200 && response.data) {
        const cameras = response.data.map(
          (val) =>
            new Camera(
              val["uuId"],
              val["label"],
              val["description"],
              val["imei"],
              val["manufacturerName"],
              val["modelName"],
              val["streamURL"],
              val["key"],
              val["channel"],
              val["status"],
              val["createdDate"]
            )
        );
        store.dispatch(cameraActions.setCameras(cameras));
        return true;
      }
    } catch (error) {}

    return false;
  }

  static async fetchCameraById(operatorId, id) {
    try {
      let response = await Http.get(`operator/${operatorId}/camera/${id}`);
      if (response.status === 200 && response.data) {
        return response.data;
      }
    } catch (error) {}

    return false;
  }

  static async createCameraToAimacServer(baseUrl, cameraData) {
    try {
      const response = await fetch(`${baseUrl}/camera`, {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: JSON.stringify(cameraData),
      });
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async updateCameraToAimacServer(baseUrl, cameraData) {
    try {
      const response = await fetch(`${baseUrl}/camera`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
        },
        body: JSON.stringify(cameraData),
      });
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async deleteCameraToAimacServer(baseUrl, id) {
    try {
      const data = {
        uuId: id,
      };
      const response = await fetch(`${baseUrl}/camera`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      });
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async createCamera(operatorId, cameraData) {
    try {
      let response = await Http.post(
        `operator/${operatorId}/camera`,
        cameraData
      );
      if (response.status === 200) return response.data;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async updateCamera(operatorId, cameraData) {
    try {
      let response = await Http.put(
        `operator/${operatorId}/camera/${cameraData["uuId"]}`,
        cameraData
      );
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async deleteCamera(operatorId, id) {
    try {
      let response = await Http.delete(`operator/${operatorId}/camera/${id}`);
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  //**********************************************************************
  //*********************** PLANS ****************************************
  //**********************************************************************
  static async fetchPlans(operatorId) {
    return (
      (await this.getRequest(`operator/${operatorId}/plan`, (response) => {
        const plans = response.data.map(
          (val) =>
            new Plan(
              val["planId"],
              val["planName"],
              val["status"],
              val["createdDate"]
            )
        );
        store.dispatch(planActions.setPlans(plans));
      })) != null
    );
  }

  static async fetchPlanById(operatorId, id) {
    try {
      let response = await Http.get(`operator/${operatorId}/plan/${id}`);
      if (response.status === 200 && response.data) {
        return response.data;
      }
    } catch (error) {}

    return false;
  }

  static async createPlan(operatorId, planData) {
    try {
      let response = await Http.post(`operator/${operatorId}/plan`, planData);
      if (response.status === 201) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async updatePlan(operatorId, planData) {
    try {
      let response = await Http.put(
        `operator/${operatorId}/plan/${planData["planId"]}`,
        planData
      );
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async deletePlan(operatorId, id) {
    try {
      let response = await Http.delete(`operator/${operatorId}/plan/${id}`);
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  //**********************************************************************
  //*********************** Payouts ****************************************
  //**********************************************************************
  static async fetchPayouts(operatorId) {
    try {
      console.log("-->ApiHelper :: fetchPayouts ()");
      store.dispatch(payoutActions.setPayouts([]));
      let response = await Http.get(`operator/${operatorId}/payouts`);
      if (response.status === 200 && response.data) {
        const payouts = response.data.map(
          (val) =>
            new Payout(
              val["payoutsId"],
              val["payerName"],
              val["payeeName"],
              val["payoutType"],
              val["payoutAmountCurrency"],
              val["payoutAmount"],
              val["status"],
              val["createdDate"]
            )
        );

        store.dispatch(payoutActions.setPayouts(payouts));
        return true;
      }
    } catch (error) {}

    return false;
  }

  static async fetchPayoutById(operatorId, id) {
    try {
      let response = await Http.get(`operator/${operatorId}/payouts/${id}`);
      if (response.status === 200 && response.data) {
        return response.data;
      }
    } catch (error) {}

    return false;
  }

  static async createPayouts(operatorId, payoutData) {
    try {
      let response = await Http.post(
        `operator/${operatorId}/payouts`,
        payoutData
      );
      if (response.status === 201) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async updatePayouts(operatorId, payoutData) {
    try {
      let response = await Http.put(
        `operator/${operatorId}/payouts/${payoutData["payoutsId"]}`,
        payoutData
      );
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async deletePayouts(operatorId, id) {
    try {
      let response = await Http.delete(`operator/${operatorId}/payouts/${id}`);
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  //**********************************************************************
  //*********************** WALLETS **************************************
  //**********************************************************************
  static async fetchWallets() {
    try {
      console.log("-->ApiHelper :: fetchWallets ()");
      store.dispatch(walletActions.setWallets([]));
      let response = await Http.get("wallet");
      if (response.status === 200 && response.data) {
        const wallets = response.data.map(
          (val) => new Wallet(val["walletId"], val["walletName"])
        );
        store.dispatch(walletActions.setWallets(wallets));
        return true;
      }
    } catch (error) {}

    return false;
  }

  static async fetchWalletById(id) {
    try {
      let response = await Http.get(`wallet/${id}`);
      if (response.status === 200 && response.data) {
        return response.data;
      }
    } catch (error) {}

    return false;
  }

  static async createWallet(walletData) {
    try {
      let response = await Http.post("wallet", walletData);
      if (response.status === 201) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async updateWallet(walletData) {
    try {
      let response = await Http.put(
        `wallet/${walletData["walletId"]}`,
        walletData
      );
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async deleteWallet(id) {
    try {
      let response = await Http.delete(`wallet/${id}`);
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  //**********************************************************************
  //*********************** INVOICES **************************************
  //**********************************************************************
  static async fetchInvoices(operatorId) {
    try {
      console.log("-->ApiHelper :: fetchInvoices ()");
      store.dispatch(invoiceActions.setInvoices([]));
      let response = await Http.get(`operator/${operatorId}/invoice`);
      if (response.status === 200 && response.data) {
        const invoices = response.data.map(
          (val) =>
            new Invoice(
              val["invoiceId"],
              val["invoiceAmount"],
              val["status"],
              val["createdDate"],
              val["invoicePaidDate"]
            )
        );
        store.dispatch(invoiceActions.setInvoices(invoices));
        return true;
      }
    } catch (error) {}

    return false;
  }

  static async fetchInvoiceById(operatorId, id) {
    try {
      let response = await Http.get(`operator/${operatorId}/invoice/${id}`);
      if (response.status === 200 && response.data) {
        return response.data;
      }
    } catch (error) {}

    return false;
  }

  static async createInvoice(operatorId, invoiceData) {
    try {
      let response = await Http.post(
        `operator/${operatorId}/invoice`,
        invoiceData
      );
      if (response.status === 201) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async updateInvoice(operatorId, invoiceData) {
    try {
      let response = await Http.put(
        `operator/${operatorId}/invoice/${invoiceData["invoiceId"]}`,
        invoiceData
      );
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async deleteInvoice(operatorId, id) {
    try {
      let response = await Http.delete(`operator/${operatorId}/invoice/${id}`);
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async payInvoice(operatorId, invoiceId) {
    try {
      let response = await Http.post(
        `operator/${operatorId}/invoice/${invoiceId}`
      );
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async getPaymentDetailsById(id, isSubscriber) {
    try {
      axios.defaults.headers.common["Token"] = "admin";
      let response = await Http.get(
        `${isSubscriber ? "subscriber" : "operator"}/${id}/payment`
      );

      if (response.status === 200) return response.data;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async getStripePublicKey() {
    try {
      let response = await Http.get(`stripe/publickey`);

      if (response.status === 200) return response.data;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  //**********************************************************************
  //*********************** BLOCKS  **************************************
  //**********************************************************************
  static async fetchBlocks() {
    try {
      console.log("-->ApiHelper :: fetchBlocks ()");
      store.dispatch(blockActions.setBlocks([]));
      let response = await Http.get("block");
      if (response.status === 200 && response.data) {
        console.log("response.data", response.data);
        const blocks = response.data.map(
          (val) =>
            new Block(
              val["blockId"],
              val["blockHash"],
              val["createdDate"],
              val["transactionsCount"],
              val["transactions"]
            )
        );
        store.dispatch(blockActions.setBlocks(blocks));
        return true;
      }
    } catch (error) {}

    return false;
  }

  static async fetchBlockById(id) {
    try {
      let response = await Http.get(`block/${id}`);
      if (response.status === 200 && response.data) {
        return response.data;
      }
    } catch (error) {}

    return false;
  }

  static async createBlock(blockData) {
    try {
      let response = await Http.post("block", blockData);
      if (response.status === 201) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async updateBlock(blockData) {
    try {
      let response = await Http.put(`block/${blockData["blockId"]}`, blockData);
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async deleteBlock(id) {
    try {
      let response = await Http.delete(`block/${id}`);
      if (response.status === 200) return true;
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async mintTokens(account, tokenId, amount) {
    try {
      await Http.put(`mint/${account}/${tokenId}/${amount}`);
    } catch (error) {
      console.error(error);
      return false;
    }

    return true;
  }

  static async downloadToken(dsimId, subscriberId) {
    try {
      let response = await Http.put(`downloadtoken/${dsimId}/${subscriberId}`);
      return response;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  static async transferToken(orderId, to, tokenId) {
    try {
      let response = await Http.put(
        `transfertoken/${orderId}/${to}/${tokenId}`
      );
      return response;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  static async getNftRequestStatus(orderId) {
    try {
      let response = await Http.get(`mint-nft-request/status/${orderId}`);
      if (response.status === 200 && response.data) {
        return response.data;
      }
    } catch (error) {
      console.log("getNftRequestStatus error", error);
    }

    return false;
  }

  static async getFtRequestStatus(orderId) {
    try {
      let response = await Http.get(`mint-ft-request/status/${orderId}`);
      if (response.status === 200 && response.data) {
        return response.data;
      }
    } catch (error) {
      console.log("getFtRequestStatus error", error);
    }

    return false;
  }

  static async getDeploymentInfo(contractName) {
    try {
      let response = await Http.get(
        `deployment-info?contractName=${contractName}`
      );
      if (response.status === 200 && response.data) {
        return response.data;
      }
    } catch (error) {
      console.log("getDeploymentInfo error", error);
    }

    return null;
  }

  static async startPersonalization(data) {
    try {
      let response = await Http.post(`startpersonalization`, data);
      if (response.status === 200 && response.data) {
        return response.data;
      }
    } catch (error) {}
  }

  static async finishPersonalization(data) {
    try {
      let response = await Http.post(`finishpersonalization`, data);
      if (response.status === 200 && response.data) {
        return response.data;
      }
    } catch (error) {}
  }

  static async postMintFtRequest(data) {
    try {
      let response = await Http.post(`mint-ft-request`, data);
      if (response.status === 200 && response.data) {
        return response.data;
      }
    } catch (error) {
      console.log("postMintFtRequest error", error);
    }

    return null;
  }

  static async postMintNftRequest(data) {
    try {
      let response = await Http.post(`mint-nft-request`, data);
      if (response.status === 200 && response.data) {
        return response.data;
      }
    } catch (error) {
      console.log("postMintFtRequest error", error);
    }

    return null;
  }

  static async getGeolocation() {
    try {
      let response = await Http.get("geolocation");

      if (response.status === 200 && response.data) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  static async isOperatorEnrolled() {
    try {
      let response = await Http.get("operatorstatus");
      if (response.status === 200 && response.data) {
        return response.data.isEnrolled;
      }
    } catch (error) {
      console.error(error);
    }

    return false;
  }
}

export default ApiHelper;

export const BASE_URL = config.API_URL;
/**
 * Reusable function to perform a GET request with a condition
 * @param {string} path Relative path to the endpoint (without base URL)
 * @param {boolean} condition Condition to build the final URL
 */
export async function fetchGetWithCondition(path, condition) {
  const user = LocalStorageHelper.getUserInfo();
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "X-Requested-With": "XMLHttpRequest",
    crossdomain: "true",
  };

  if (user?.accessToken) {
    headers["Authorization"] = `Bearer ${user.accessToken}`;
  }

  try {
    const finalUrl = `${BASE_URL}${path}?type=${condition ? "one" : "other"}`;
    const response = await fetch(finalUrl, { method: "GET", headers });

    if (!response.ok) {
      toast.error(`Server error: ${response.status}`);
      throw new Error(`Server error: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error("fetchGetWithCondition error:", error);
    throw error;
  }
}

/**
 * Reusable function to perform generic requests with flexible methods
 * @param {string} path Relative path to the endpoint (without base URL)
 * @param {string} method HTTP method (GET, POST, PUT, DELETE, etc.)
 * @param {Object} [body] Request body for methods like POST/PUT
 */ export async function customFetch(endpoint, method = "GET", body = null) {
  const user = LocalStorageHelper.getUserInfo();

  const headers = {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
    Accept: "application/json",
    "Accept-Encoding": "gzip, deflate, br, zstd",
    Token: user?.accessToken || "",
  };

  const config = {
    method,
    headers,
    mode: "cors",
  };

  if (body && ["POST", "PUT", "PATCH"].includes(method)) {
    config.body = JSON.stringify(body);
  }

  const finalUrl = `${BASE_URL}${endpoint}`;

  try {
    const res = await fetch(finalUrl, config);

    const json = await res.json();

    return json;
  } catch (error) {
    console.error("customFetch error:", error);
    throw error;
  }
}
